import {
  container,
  title,
  main,
  whiteColor,
  grayColor,
  mainRaised,
  cardTitle,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";
import footerStyle from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";

const presentationStyle = {
  ...footerStyle,
  ...customSelectStyle,
  main: {
    ...main
    /*overflow: "hidden"*/
  },
  cardTitle: {
    ...cardTitle,
    paddingTop: "50px",
    paddingLeft: "25px",
    /*overflow: "hidden"*/
  },
  title2: {
    ...title,
    paddingLeft: "25px",
  },
  mainRaised,
  parallax: {
    height: "50vh",
    overflow: "hidden"
  },
  container: {
    ...container,
    zIndex: 1
  },
  description: {
    color: grayColor[0],
    paddingLeft: "25px",
  },
  description2: {
    color: grayColor[1],
    paddingLeft: "30px",
  },
  description3: {
    color: grayColor[0],
    paddingLeft: "40px",
  },
  descriptionJob: {
    color: grayColor[0],
    paddingLeft: "25px",
    marginTop: "-10px",
    paddingBottom: "10px",
  },
  descriptionDomaine: {
    color: grayColor[0],
    paddingLeft: "25px",
    paddingTop: "10px",
  },
  title: {
    ...title,
    color: whiteColor
  },
  brand: {
    color: whiteColor,
    textAlign: "center",
    "& h1": {
      fontSize: "4.2rem",
      fontWeight: "600",
      display: "inline-block",
      position: "relative"
    }
  },
  imgbloc: {
    paddingTop: "20px",
  },
  features: {
    textAlign: "center",
  },
  stats: {
    color: grayColor[0],
    display: "flex",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      fontSize: "18px",
      lineHeight: "18px"
    },
    "& svg": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      width: "18px",
      height: "18px"
    }
  },
  justifyContentCenter: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important"
  },
  cardTitleWhite: {
    "&, & a": {
      ...title,
      textAlign: "center !important",
      marginTop: ".625rem",
      marginBottom: "0",
      minHeight: "auto",
      color: whiteColor + " !important"
    }
  },
  cardDescriptionWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)"
  },
  buttonCenter: {
    textAlign: "center",
  },
  modalTitle: {
    ...title,
    textAlign: "center",
    margin: "0",
  },
  modalBody: {
    textAlign: "center",
    color: grayColor[0],
    paddingBottom: "12px",
    paddingLeft: "50px",
    paddingRight: "50px",
    position: "relative"
  },
  modalFooter: {
    padding: "15px",
    textAlign: "right",
    paddingTop: "0",
    margin: "0"
  },
  modalFooterCenter: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "30px",
  }
};

export default presentationStyle;
